import { NodeViewWrapper } from '@tiptap/react'
import { Indicator } from '../../../../../components/ui';

export const UploadIndicator = () => {
  return (
    <NodeViewWrapper>
      <div className='p-0 m-0' data-drag-handle>
        <Indicator isSpinning />
      </div>      
    </NodeViewWrapper>
  ) 
}

export default UploadIndicator
