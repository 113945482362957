import React, { FC } from 'react'
import { Button, Dialogue, Icon, Form, Avatar, Tooltip, Indicator } from '../../../components/ui'
import Option from '../../../components/shared/Option'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../../store/layout/modalSlice'
import { Field, Formik } from 'formik'
import AsyncSelect from 'react-select/async'
import Select from '../../../components/ui/Select'
import useJourneyOptions from '../../../hooks/useJourneyOptions'
import useAssigneeOptions from '../../../hooks/useAssigneeOptions'
import usePhaseOptions from '../../../hooks/usePhaseOptions'
import EditableText from '../../../components/shared/EditableText'
import usePrograms from '../../../hooks/usePrograms'
import { CSSObjectWithLabel } from 'react-select'
import useStepTypeOptions from '../../../hooks/useStepTypeOptions'
import {
  ContentStepType,
  StepDetailDocument,
  StepType,
  useJourneysDropdownQuery,
  useCreateStepMutation,
} from '../../../graphql'
import * as Yup from 'yup'
import { useNavigate } from 'react-router'
import formatRouteId from '../../../utils/formatRouteId'

const validationSchema = Yup.object().shape({
  phase: Yup.object()
    .shape({
      id: Yup.string().required('Phase is required'),
    })
    .required('Phase is required'),
  name: Yup.string().required('Title is required'),
  description: Yup.string().nullable(),
  journey: Yup.object()
    .shape({
      id: Yup.string().required('Journey is required'),
    })
    .required('Journey is required'),
  type: Yup.object()
    .shape({
      value: Yup.string().required('Type is required'),
    })
    .required('Type is required'),
  assignee: Yup.object()
    .shape({
      id: Yup.string().nullable(),
    })
    .nullable(),
})

type NewStepFields = {
  phase: {
    id: string
  } | null
  name: string | null
  description: string | null
  journey: {
    id: string
  } | null
  type: {
    value: 'task' | 'reading' | 'video'
    detail: {
      type: StepType
      contentType: ContentStepType
    }
  } | null
  assignee: {
    id: string
  } | null
}

const NewStep: FC = () => {
  const { selectedProgram } = usePrograms()
  const dispatch = useDispatch()
  const [journeyProps] = useJourneyOptions({ size: 'xs', programId: selectedProgram?.id })
  const [assigneeProps] = useAssigneeOptions({ size: 'xs', programId: selectedProgram?.id, emptyOption: true })
  const [phaseProps] = usePhaseOptions({ size: 'xs', programId: selectedProgram?.id })
  const [typeProps] = useStepTypeOptions({ size: 'xs' })

  const navigate = useNavigate()

  const [createStep] = useCreateStepMutation({
    onError: (error) => {
      console.log(error)
    },
    update: (cache, { data }) => {
      if (data?.createStep.__typename === 'CreateStepSuccess') {
        try {
          cache.writeQuery({
            query: StepDetailDocument,
            variables: {
              where: {
                id: data.createStep.step.id,
              },
            },
            data: {
              step: {
                ...data.createStep.step,
                //editing: true,
              },
            },
          })
        } catch (e) {
          console.error(e)
        }
        const { routeId } = formatRouteId(data.createStep.step.name, data.createStep.step.id)
        navigate(`/step/${routeId}`)
        dispatch(closeModal())
        //Todo: toast when step is created
      }
    },
  })

  const { data: journeyData, loading } = useJourneysDropdownQuery({
    variables: {
      programId: selectedProgram?.id || '',
    },
    fetchPolicy: 'cache-and-network',
  })

  let singleJourney: { id: string; name: string } | undefined
  if (journeyData?.journeys?.total === 1) {
    const j = journeyData?.journeys?.list[0]
    singleJourney = j && {
      id: j.id,
      name: j.name,
    }
  }

  const initialValues: NewStepFields = {
    phase: null,
    name: null,
    description: null,
    journey: singleJourney ? singleJourney : null,
    type: null,
    assignee: null,
  }

  return loading && !journeyData ? (
    <div className={'flex justify-center items-center max-w-[749px] w-[calc(100vw-80px)] h-[203.5px]'}>
      <Indicator isSpinning />
    </div>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      isInitialValid={false}
      onSubmit={async (values) => {
        console.log('submit', values)

        let defaultStep = false
        if (values.assignee?.id === 'default') {
          defaultStep = true
        }

        await createStep({
          variables: {
            data: {
              name: values.name || '',
              description: values.description,
              phase: {
                connect: {
                  id: values.phase!.id,
                },
              },
              journey: {
                connect: {
                  id: values.journey!.id,
                },
              },
              default: defaultStep,
              type: values.type!.detail.type,
              contentType: values.type!.detail.contentType,
              assignees:
                !defaultStep && values.assignee?.id
                  ? {
                    connect: [
                      {
                        id: values.assignee.id,
                      },
                    ],
                  }
                  : null,
            },
          },
        })
      }}>
      {({ errors, touched, isValid, isSubmitting }) => (
        <Form>
          <div className={'max-w-[749px] w-[calc(100vw-80px)]'}>
            <Dialogue.Header
              className={'w-full relative'}
              wrapperClassName={'mb-[15px]'}
              closable
              onClose={() => dispatch(closeModal())}>
              <div className={'flex gap-[5px] items-center flex-wrap relative top-[-5px]'}>
                <Tooltip title={selectedProgram?.name} delay={600} placement={'bottom'}>
                  <Option
                    className={'h-[30px] max-w-[175px] truncate'}
                    name={selectedProgram?.name || ''}
                    fontSize={'sm'}
                    prefix={
                      <Avatar
                        size={18}
                        shape={'square'}
                        src={selectedProgram?.image?.url}
                        name={selectedProgram?.name}
                      />
                    }
                  />
                </Tooltip>
                <div className={'flex gap-[5px] items-center'}>
                  <Icon name={'chevron-right'} />
                  <div className={'max-w-[175px]'}>
                    <Field
                      name={'phase'}
                      {...phaseProps}
                      tooltip={{
                        title: touched.phase && errors.phase ? errors.phase : 'Add to phase',
                        delay: 500,
                        placement: 'bottom',
                      }}
                      size={'xs'}
                      key={'phase-dropdown'}
                      cacheOptions
                      defaultOptions
                      styles={{
                        menu: (provided: CSSObjectWithLabel) => ({
                          ...provided,
                          minWidth: 207,
                          maxWidth: 350,
                        }),
                      }}
                      component={Select}
                      componentAs={AsyncSelect}
                    />
                  </div>
                </div>
              </div>
            </Dialogue.Header>
            <div className={'flex flex-col gap-[20px]'}>
              <div className={'flex flex-col gap-[10px]'}>
                <Form.Item
                  errorMessage={touched.name && errors.name ? errors.name : undefined}
                  invalid={touched.name && !!errors.name}>
                  <Field name={'name'} component={EditableText} placeholder={'Step title'} className={'h2'} />
                </Form.Item>
                <Field name={'description'} component={EditableText} placeholder={'Add a description'} />
              </div>
              <div className={'flex gap-[10px] flex-wrap'}>
                {!singleJourney && (
                  <div className={'max-w-[175px]'}>
                    <Field
                      name={'journey'}
                      {...journeyProps}
                      tooltip={{
                        title: touched.journey && errors.journey ? errors.journey : 'Add to journey',
                        delay: 600,
                        placement: 'bottom',
                      }}
                      size={'xs'}
                      key={'journey-dropdown'}
                      cacheOptions
                      defaultOptions
                      styles={{
                        menu: (provided: CSSObjectWithLabel) => ({
                          ...provided,
                          minWidth: 207,
                          maxWidth: 350,
                        }),
                      }}
                      component={Select}
                      componentAs={AsyncSelect}
                    />
                  </div>
                )}
                <div className={'max-w-[175px]'}>
                  <Field
                    name={'type'}
                    size={'xs'}
                    {...typeProps}
                    tooltip={{
                      title: touched.type && errors.type ? errors.type : 'Change type',
                      delay: 600,
                      placement: 'bottom',
                    }}
                    styles={{
                      menu: (provided: CSSObjectWithLabel) => ({
                        ...provided,
                        minWidth: 207,
                        maxWidth: 350,
                      }),
                    }}
                    component={Select}
                  />
                </div>
                <div className={'max-w-[160=5px]'}>
                  <Field
                    name={'assignee'}
                    {...assigneeProps}
                    tooltip={{
                      title: 'Assign to',
                      delay: 600,
                      placement: 'bottom',
                    }}
                    clearOnNullValue
                    size={'xs'}
                    key={'assignee-dropdown'}
                    cacheOptions
                    defaultOptions
                    styles={{
                      menu: (provided: CSSObjectWithLabel) => ({
                        ...provided,
                        minWidth: 207,
                        maxWidth: 350,
                      }),
                    }}
                    component={Select}
                    componentAs={AsyncSelect}
                  />
                </div>
              </div>
            </div>
            <Dialogue.Footer>
              <Button loading={isSubmitting} disabled={!isValid} size={'sm'} variant={'solid'}>
                Create step
              </Button>
            </Dialogue.Footer>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default NewStep
