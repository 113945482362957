import React, { useEffect, useState, MouseEventHandler, FC } from 'react'
import { ReactSVG, WrapperType } from 'react-svg'
import classNames from 'classnames'

export type IconProps = {
  name: IconName
  size?: number | string
  color?: string
  className?: string
  onClick?: MouseEventHandler<WrapperType> & MouseEventHandler<SVGSVGElement>
}

const Icon: FC<IconProps> = ({ name, size = 20, color = 'gray-900', className = '', onClick }) => {
  const [icon, setIcon] = useState('')

  useEffect(() => {
    import(`../../../assets/icons/icon-${name}.svg`).then((res) => {
      setIcon(res.default)
    })
    /*const sprite = `img/icons/icon-${name}.svg`
    const prefetchLink = document.createElement('link')

    prefetchLink.href = sprite
    prefetchLink.rel = 'prefetch'
    prefetchLink.as = 'image'
    document.body.appendChild(prefetchLink)*/
  }, [name])

  return (
    <div
      style={{ height: size, width: size }}
      className={classNames(!color.startsWith('#') && `text-${color}`, className)}>
      <ReactSVG
        beforeInjection={(svg) => {
          if (color.startsWith('#')) {
            // Convert NodeListOf<Element> to an array and then iterate
            Array.from(svg.querySelectorAll('path')).forEach((path) => {
              path.setAttribute('fill', color)
            })
          }
          svg.setAttribute('height', `${size}`)
          svg.setAttribute('width', `${size}`)
        }}
        src={icon}
        onClick={onClick}
      />
    </div>
  )
}

export default Icon
