import { useState } from 'react'
import { Editor, NodeViewWrapper } from '@tiptap/react'
import { useCallback } from 'react'

import { ImageUploader } from './ImageUploader'
import { Indicator } from '../../../../../../components/ui'

export const ImageUpload = ({ getPos, editor }: { getPos: () => number; editor: Editor }) => {
  const [isUploading, setIsUploading] = useState(false)

  const onUpload = useCallback(
    (url: string) => {
      if (url) {
        editor.chain().setImageBlock({ src: url }).deleteRange({ from: getPos(), to: getPos() }).focus().run()
      }
    },
    [getPos, editor],
  ) 

  const onUploadStart = () => {
    setIsUploading(true)
  }


  if (isUploading) {
    return (
      <NodeViewWrapper>
        <Indicator isSpinning />
      </NodeViewWrapper>
    )
  }

  return (
    <NodeViewWrapper>
      <div className="p-0 m-0" data-drag-handle>
        <ImageUploader onUpload={onUpload} onUploadStart={onUploadStart}/>
      </div>
    </NodeViewWrapper>
  )
}

export default ImageUpload
