import { Node, ReactNodeViewRenderer } from '@tiptap/react'
import { Range } from '@tiptap/core'
import { UploadIndicator as UploadIndicatorComponent } from './UploadIndicatorComponent';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    uploadIndicator: {
      setUploadIndicator: () => ReturnType
      setUploadIndicatorAt: (attributes: { pos: number | Range }) => ReturnType

    }
  }
}

export const UploadIndicator = Node.create({
  name: 'uploadIndicator',

  isolating: true,

  defining: true,

  group: 'block',

  draggable: true,

  selectable: true,

  inline: false,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML() {
    return ['div', { 'data-type': this.name }]
  },

  addCommands() {
    return {
      setUploadIndicator:
        () =>
        ({ commands }) =>
          commands.insertContent(`<div data-type="${this.name}"></div>`),
      
      setUploadIndicatorAt:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContentAt(attrs.pos, { type: 'uploadIndicator' })
        },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(UploadIndicatorComponent)
  },
})
