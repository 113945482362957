import { client } from '../../../../services'
import { 
  UploadCredentialsDocument, 
  UploadCredentialsQueryVariables, 
  ProgramDropdownDocument, 
  ProgramDropdownQueryVariables, 
} from "../../../../graphql";
import { UploadFile, UploadFileParams } from '../../../../utils/upload';
import { gql } from '@apollo/client';

type UserInfo = {
  user: {
    id: string
    lastViewdProgram: string | null
    programs: {
      __typename: string,
      id: string
    }[]
    viewingProgramId: string
    __typename: string
  }
}

const getUserInfo = async () => {
  const user = client!.cache.readFragment<UserInfo>({
    id: 'Auth:{}',
    fragment: gql`
      fragment userAuthFields on UserAuth {
        user {
          id
          viewingProgramId @client
          lastViewedProgram {
            id 
          }
          programs {
            id
          }    
        }
      }
    `
  })

  return {...user?.user}
}

const getPrograms = async ({variables}: {variables: ProgramDropdownQueryVariables}) => {
  const { data } = await client!.query({
    query: ProgramDropdownDocument,
    variables,
  })

  return data.programs.list
}

const getSelectedProgram = (programs: any, lastViewedProgramID: string | undefined | null, viewingProgramID: string | undefined | null) => {
  return programs.find((p: any) => {
    return p.id === lastViewedProgramID || p.id === viewingProgramID
  })
}

const getOrgID = async () => {
  let userInfo = await getUserInfo()
  let programs = await getPrograms({
    variables: {
      userId: userInfo.id || ''
    }
  })
  let selectedProgram = getSelectedProgram(programs, userInfo.lastViewdProgram, userInfo.viewingProgramId)

  return selectedProgram.tenant.id
}

const uploadCredentials = async ({variables}: {variables: UploadCredentialsQueryVariables}) => {
  return await client?.query({
    query: UploadCredentialsDocument,
    variables,
  })
}

const onGetCredentials = async (tenantId: string) => {
    try {
      const creds = await uploadCredentials({
        variables: {
          tenantId,
        },
      })
      if (creds?.data?.uploadCredentials) {
        return {
          key: creds.data.uploadCredentials.key,
          resource: creds.data.uploadCredentials.resource,
          resourceName: creds.data.uploadCredentials.resourceName,
          accessKeyId: creds.data.uploadCredentials.accessKeyId,
          secretAccessKey: creds.data.uploadCredentials.secretAccessKey,
          sessionToken: creds.data.uploadCredentials.sessionToken,
        }
      }
    } catch (e) {
      console.log(e)
    }
    throw new Error('Failed to get AWS credentials')
  }

export const uploadFile = async (params: UploadFileParams) => {
  const fileUpload = new UploadFile(params)
  await fileUpload.start()
}

export type UploadImageParams = {
  _file: File
  onUploadStart?: () => void
  onUploadComplete?: (src: string) => void
}

export class API {
  public static uploadImage = async ({_file, onUploadStart, onUploadComplete}: UploadImageParams) => {
    // TODO: Create a placeholder progress image
    // TODO: for some reason the onGetCredentials doesnt refresh the token if its expired, but adding an image with the menu or slash command does
    let orgID = await getOrgID();
    let imgSrc;

    if (onUploadStart) {
      onUploadStart()
    }

    await uploadFile({
      getCredentials: () => onGetCredentials(orgID),
      src: undefined, 
      blob: _file,
      fileName: _file.name,
      onComplete: ({ src }) => {
        imgSrc = src
        if (onUploadComplete) {
          onUploadComplete(src)
        }
      },
    })

    return imgSrc || ''
  }
}

export default API
